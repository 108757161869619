import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import { MHPageProps } from "../types"
import { MHArticle } from "../components/MHArticle"
import { Unpublished } from "../components/Unpublished"

class NowPage extends React.Component<MHPageProps> {
  render() {
    const { data } = this.props
    const { siteUrl } = data.site.siteMetadata
    const post = data.allMdx.edges[0]
    const node = post.node
    const previous = data.allMdx.edges[1]
    const dateTitle = node.frontmatter.nowDate ?? node.frontmatter.formattedDate

    return (
      <>
        <SEO title="What I'm up to" />

        <div className="oneColumn">
          <MHArticle
            title="What I'm up to"
            byline={
              <>
                Last update:{" "}
                <Link
                  to={node.fields.slug}
                  rel="canonical"
                  className="plain-link"
                >
                  {dateTitle}
                </Link>
                {node.frontmatter.published === false && <Unpublished />}
              </>
            }
            date={node.frontmatter.date}
            updatedDate={node.frontmatter.updatedDate}
            showDate={false}
            subtitle={
              <>
                An overview of stuff that I'm working on, and other stuff that
                keeps me occupied. Inspired by the movement of{" "}
                <a
                  href="https://nownownow.com/about"
                  target="_blank"
                  rel="noopener"
                  className="g-link"
                >
                  /now pages
                </a>
                .
              </>
            }
            body={node.body}
            asArticle
          >
            <meta itemProp="url" content={siteUrl + node.fields.slug} />
          </MHArticle>

          <div role="presentation" className="hr n-vspacing-medium" />

          <div className="flex">
            <Link
              to={previous.node.fields.slug}
              rel="previous"
              className="g-link n-type-smallest n-color-mid"
            >
              ← Previous update (
              {previous.node.frontmatter.nowDate ??
                previous.node.frontmatter.formattedDate}
              )
            </Link>
          </div>
        </div>
      </>
    )
  }
}

export default NowPage

export const pageQuery = graphql`
  query ($publishedFilter: BooleanQueryOperatorInput) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMdx(
      # we want to know about the next one as well
      limit: 2
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        fields: { contentType: { eq: "now" } }
        frontmatter: { published: $publishedFilter }
      }
    ) {
      edges {
        node {
          body
          fields {
            slug
            contentType
          }
          frontmatter {
            date
            published
            updatedDate
            nowDate
            formattedDate: date(formatString: "MMMM YYYY")
          }
        }
      }
    }
  }
`
